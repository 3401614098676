.tickerShell {
  align-self: stretch;
  display: flex;
  justify-content: center;

  .timeIsUp {
    flex: 1;
    text-align: center;
    font-size: 3.5rem;
    text-transform: uppercase;
    font-weight: 600;
  }

  .separator {
    color: #333;
    @apply text-xl md:text-2xl lg:text-3xl xl:text-4xl;
  }
}
