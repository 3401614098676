.pageContainer {
  background-image: url("../../assets/images/timer-background/background.svg");
  @apply flex flex-col items-center justify-center w-full h-screen bg-top bg-cover;

  .timerContainer {
    color: #ea1b23;
    @apply my-12 border-transparent w-11/12 md:w-4/5 lg:w-3/5;
  }

  h1 {
    color: #333333;
    @apply font-semibold uppercase text-3xl md:text-5xl xl:text-6xl mt-5 md:mt-0;
  }

  h3 {
    color: #333333;
    @apply m-0 text-xl md:text-3xl xl:text-4xl;
  }

  .iconContainer {
    @apply flex justify-center;

    .icon {
      @apply mx-6;
    }
  }
}
