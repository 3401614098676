@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Montserrat", sans-serif;
}

#root {
  display: grid;
  place-items: center center;
  flex: 1;
}
